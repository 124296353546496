<section class="content">
    <div class="container-fluid">
        <div class="row mt-2 mb-5">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-3 mt-lg-3 mt-5">
                        <h3 class="mt-3 mb-3">Product Details <img class="img-fluid ml-3" alt="clear"
                                                                   src="assets/img/pen-circle.png" height="32"
                                                                   width="32" type="button" matTooltip="click to edit"
                                                                   (click)="editProductDetails()">
                        </h3>
                    </div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row" *ngIf="isVerified != 'Approved'">
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-success-green btn-block"
                                                (click)="approveStatus('Approved', productId)">
                                            Approve
                                        </button>
                                    </div>
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-danger-red btn-block"
                                                (click)="approveStatus('Not Approved', productId)">
                                            Reject
                                        </button>
                                    </div>
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-secondary-light btn-block"
                                                (click)="approveStatus('Hold', productId)">Hold
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 mt-3" *ngIf="isVerified == 'Approved'">
                                <div class="row">
                                    <div class="col-lg-9 text-end">
                                        <span class="badge-success-green btn-block p-3">Approved</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-12 mt-2">
                        <div class="row mt-2">
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Product Name</p>
                                    <span class="mt-0 fw-bold">{{productName || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Uniqueness</p>
                                    <span class="mt-0 fw-bold">{{unique || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{phoneNo || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Description</p>
                                    <span class="mt-0 fw-bold">{{description || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Location</p>
                                    <span class="mt-0 fw-bold">{{ this.location || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.userType == 'Farmers' || this.userType == 'FPO' ">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Farming Location</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.farmLocation || '-' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.userType == 'Farmers' || this.userType == 'FPO' ">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Harvest Date</p>
                                    <span class="mt-0 fw-bold"> {{ getFormattedHarvestDate(this.productDetailsById?.harvestDate) }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Price</p>
                                    <span class="mt-0 fw-bold">RS.{{price | currency:'INR':'' || '' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Tax</p>
                                    <span class="mt-0 fw-bold">{{tax || '-' }} %</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Net Price</p>
                                    <span class="mt-0 fw-bold">RS.{{netPrice | currency:'INR':'' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Quantity</p>
                                    <span class="mt-0 fw-bold">{{quantityValue}} {{quantityType}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType != 'Food Processors' && 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Packaging Type</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.packagingType || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmer Input'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Origin Country</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.originCountry || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmer Input'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Variety</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.variety || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmer Input'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Nutrient</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.nutrientComposition || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmer Input'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Nutrient</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.nutrientComposition || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.userType == 'Farmers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Application Method</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.applicationMethod || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Tags</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.tags || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Stock</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.status || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Grade</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.grade || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType != 'Farmers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Stock Quantity</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.stockQuantity || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Lot No</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.lotNumber || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Moisture</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.moistureContent || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Purity</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.purity || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Size Variants</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.sizeVariants || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Min Order Qty</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.minimumOrderQuantity || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Target Market</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.targetMarket || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.productDetailsById?.isDailyRental == true && userType == 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Daily Rent Amount</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.dailyRental || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.productDetailsById?.isWeeklyRental == true && userType == 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Weekly Rent Amount</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.weeklyRental || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="this.productDetailsById?.isMonthlyRental == true && userType == 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Monthly Rent Amount</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.monthlyRental || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Deposit Amount</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.depositAmount || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Marketing Description</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.marketingDescription || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Category</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.category || '-' }}</span>
                                </div>
                            </div>


                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmer Input' || userType == 'Commodity Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Manufacturer</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.manufacturerName || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Farmer Input'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Model Number</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.modelNumber || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmer Input' && 'Food Processors'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Certificate</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.certifications || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Farmer Input'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Origin</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.originCountry || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType != 'Food Processors' && 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Shelf Life</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.shelfLife || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType == 'Farmers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Farming Practices</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.farmingPractices || '-' }}</span>
                                </div>
                            </div>


                            <div class="col-12 col-lg-4 col-md-6 mt-2"
                                 *ngIf="userType == 'Farmers' || userType == 'Service Providers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Batch Number</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.batchNumber || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType != 'Farmers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Brochure URL</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.brochureUrl || '-' }}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="userType != 'Farmers'">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Product Video URL</p>
                                    <span class="mt-0 fw-bold">{{ this.productDetailsById?.productVideoUrl || '-' }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="container-fluid col-lg-12 col-12 mt-3 mb-3">
                        <div class="row mt-4">
                            <div class="col-lg-12 col-12">
                                <table class="table bg-white-detail table-responsive-sm table-borderless">
                                    <thead>
                                    <th>Feature Name</th>
                                    <th>Feature Value</th>
                                    <th>Friendly Name</th>
                                    <th>Friendly Value</th>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let feature of this.featureList">
                                        <td>{{feature.featureName || '-'}}</td>
                                        <td>{{feature.featureValue || '-' }}</td>
                                        <td>{{feature.friendlyName || '-' }}</td>
                                        <td>{{feature.friendlyValue || '-' }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 col-md-12 p-3 mt-3 mb-3">
                        <div class="row mt-4 mb-3">
                            <p class="mb-0">Product Images</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-6 col-md-3 mt-2" *ngFor="let image of this.productImageList">
                                <img *ngIf="image?.imageId" src="{{endPoint}}/api/image-details/get/{{image.imageId}}"
                                     type="button"
                                     class="img-fluid object-fit-contain user-product-image" loading="lazy"
                                     (click)="openModal(image)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true"
     [style.display]="imageModalShow ? 'block' : 'none'" style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="imageModalShow = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <img [src]="selectedImageUrl" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="editProductModal" tabindex="-1" role="dialog" aria-labelledby="editProductModal"
     aria-hidden="true" [style.display]="editModalShow ? 'block' : 'none'"
     style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Product Detail</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="editModalShow = false">
                    <span aria-hidden="true" class="h3">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="productFormGroup" (submit)="OnSubmit()">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Name" formControlName="productName"
                                       [ngClass]="{'is-invalid': productFormGroup.get('productName')?.invalid && productFormGroup.get('productName')?.touched,
                                                            'is-valid': productFormGroup.get('productName')?.valid && productFormGroup.get('productName')?.touched}">
                                <label class="text-gray font-weight-lighter">Product Name <span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.productName">{{ productFormErrors.productName }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Friendly Name"
                                       formControlName="friendlyName"
                                       [ngClass]="{'is-invalid': productFormGroup.get('friendlyName')?.invalid && productFormGroup.get('friendlyName')?.touched,
                                                            'is-valid': productFormGroup.get('friendlyName')?.valid && productFormGroup.get('friendlyName')?.touched}">
                                <label class="text-gray font-weight-lighter">Friendly Name</label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.friendlyName">{{ productFormErrors.friendlyName }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Description"
                                       formControlName="description"
                                       [ngClass]="{'is-invalid': productFormGroup.get('description')?.invalid && productFormGroup.get('description')?.touched,
                                                            'is-valid': productFormGroup.get('description')?.valid && productFormGroup.get('description')?.touched}">
                                <label class="text-gray font-weight-lighter">Description<span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.description">{{ productFormErrors.description }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Friendly Description"
                                       formControlName="friendlyDescription">
                                <label class="text-gray font-weight-lighter">Friendly Description</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Discount"
                                       formControlName="discount">
                                <label class="text-gray font-weight-lighter">Discount</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Tax" formControlName="tax">
                                <label class="text-gray font-weight-lighter">Tax</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text"
                                       class="form-control"
                                       placeholder="Gross Price"
                                       formControlName="grossPrice"
                                       [ngClass]="{
                   'is-invalid': productFormGroup.get('grossPrice')?.invalid && productFormGroup.get('grossPrice')?.touched,
                   'is-valid': productFormGroup.get('grossPrice')?.valid && productFormGroup.get('grossPrice')?.touched
               }"
                                       (keypress)="allowOnlyNumbersWithOneDot($event)">
                                <label class="text-gray font-weight-lighter">Gross Price<span class="text-red">*</span></label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.grossPrice">{{ productFormErrors.grossPrice }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text"
                                       class="form-control"
                                       placeholder="Net Price"
                                       formControlName="netPrice"
                                       [ngClass]="{
                   'is-invalid': productFormGroup.get('netPrice')?.invalid && productFormGroup.get('netPrice')?.touched,
                   'is-valid': productFormGroup.get('netPrice')?.valid && productFormGroup.get('netPrice')?.touched
               }"
                                       (keypress)="allowOnlyNumbersWithOneDot($event)">
                                <label class="text-gray font-weight-lighter">Net Price<span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.netPrice">{{ productFormErrors.netPrice }}</span>
                        </div>


                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Location"
                                       formControlName="location">
                                <label class="text-gray font-weight-lighter">Location</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Phone Number"
                                       formControlName="phoneNumber" maxlength="10" minlength="10"
                                       [ngClass]="{'is-invalid': productFormGroup.get('phoneNumber')?.invalid && productFormGroup.get('phoneNumber')?.touched,
                                                            'is-valid': productFormGroup.get('phoneNumber')?.valid && productFormGroup.get('phoneNumber')?.touched}">
                                <label class="text-gray font-weight-lighter">Phone Number</label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.phoneNumber">{{ productFormErrors.phoneNumber }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Uniqueness"
                                       formControlName="uniqueness">
                                <label class="text-gray font-weight-lighter">Uniqueness</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Availability"
                                       formControlName="availability">
                                <label class="text-gray font-weight-lighter">Availability</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Quantity Value"
                                       formControlName="quantityValue"
                                       [ngClass]="{
                   'is-invalid': productFormGroup.get('quantityValue')?.invalid && productFormGroup.get('quantityValue')?.touched,
                   'is-valid': productFormGroup.get('quantityValue')?.valid && productFormGroup.get('quantityValue')?.touched
               }"
                                       (keypress)="allowOnlyNumbers($event)">
                                <label class="text-gray font-weight-lighter">Quantity Value</label>
                            </div>
                            <span class="text-danger"
                                  *ngIf="productFormErrors.quantityValue">{{ productFormErrors.quantityValue }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Quantity Type"
                                       formControlName="quantityType">
                                <label class="text-gray font-weight-lighter">Quantity Type</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectCategory"
                                        aria-label="Floating label select example" formControlName="category">
                                    <option selected value="">Select a Category</option>
                                    <option *ngFor="let cat of this.categories" [value]="cat">{{ cat }}</option>
                                </select>
                                <label for="floatingSelectCategory">Select Category</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect"
                                        aria-label="Floating label select example" formControlName="tags">
                                    <option selected value="">Select a tag</option>
                                    <option *ngFor="let tag of this.tags" [value]="tag">{{ tag }}</option>
                                </select>
                                <label for="floatingSelect">Select Tag</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelectStatus"
                                        aria-label="Floating label select example" formControlName="status">
                                    <option hidden selected value="">Select a Status</option>
                                    <option *ngFor="let status of this.availableStatus"
                                            [value]="status">{{ status }}</option>
                                </select>
                                <label for="floatingSelectStatus">Select Status</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <textarea type="text" class="form-control" placeholder="Marketing Description"
                                          formControlName="marketingDescription"></textarea>
                                <label class="text-gray font-weight-lighter">Marketing Description</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="userType != 'Food Processors' && userType != 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Packaging Type"
                                       formControlName="packagingType">
                                <label class="text-gray font-weight-lighter">Packaging Type</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmers' || this.userType == 'FPO' || userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Shelf Life"
                                       formControlName="shelfLife">
                                <label class="text-gray font-weight-lighter">Shelf Life</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmers' || this.userType == 'FPO'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Harvest Date"
                                       formControlName="harvestDate">
                                <label class="text-gray font-weight-lighter">Harvest Date</label>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmers' || this.userType == 'FPO'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Farming Practices"
                                       formControlName="farmingPractices">
                                <label class="text-gray font-weight-lighter">Farming Practices</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmers' || this.userType == 'FPO'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Farm Location"
                                       formControlName="farmLocation">
                                <label class="text-gray font-weight-lighter">Farm Location</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="this.userType == 'Farmers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Storage Condition"
                                       formControlName="storageCondition">
                                <label class="text-gray font-weight-lighter">Storage Condition</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmers' || this.userType == 'Commodity Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Batch Number"
                                       formControlName="batchNumber">
                                <label class="text-gray font-weight-lighter">Batch Number</label>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Origin Country"
                                       formControlName="originCountry">
                                <label class="text-gray font-weight-lighter">Origin Country</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Crop Type"
                                       formControlName="cropType">
                                <label class="text-gray font-weight-lighter">Crop Type</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Variety"
                                       formControlName="variety">
                                <label class="text-gray font-weight-lighter">Variety</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Nutrient Composition"
                                       formControlName="nutrientComposition">
                                <label class="text-gray font-weight-lighter">Nutrient Composition</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmer Input' || this.userType == 'Commodity Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Manufacturer Name"
                                       formControlName="manufacturerName">
                                <label class="text-gray font-weight-lighter">Manufacturer Name</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="this.userType == 'Farmer Input' || this.userType == 'Commodity Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Brand Name"
                                       formControlName="brandName">
                                <label class="text-gray font-weight-lighter">Brand Name</label>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="this.userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Application Method"
                                       formControlName="applicationMethod">
                                <label class="text-gray font-weight-lighter">Application Method</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="this.userType == 'Farmer Input'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Model No"
                                       formControlName="modelNumber">
                                <label class="text-gray font-weight-lighter">Model No</label>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="userType == 'Farmer Input' || userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Certification"
                                       formControlName="certifications">
                                <label class="text-gray font-weight-lighter">Certification</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType != 'Farmers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Stock Quantity"
                                       formControlName="stockQuantity">
                                <label class="text-gray font-weight-lighter">Stock Quantity</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Grade"
                                       formControlName="grade">
                                <label class="text-gray font-weight-lighter">Grade</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Product Origin"
                                       formControlName="productOrigin">
                                <label class="text-gray font-weight-lighter">Product Origin</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Moisture Content"
                                       formControlName="moistureContent">
                                <label class="text-gray font-weight-lighter">Moisture Content</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Minimum Order Quantity"
                                       formControlName="minimumOrderQuantity">
                                <label class="text-gray font-weight-lighter">Minimum Order Quantity</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Target Market"
                                       formControlName="targetMarket">
                                <label class="text-gray font-weight-lighter">Target Market</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Purity"
                                       formControlName="purity">
                                <label class="text-gray font-weight-lighter">Purity</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Food Processors'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Lot Number"
                                       formControlName="lotNumber">
                                <label class="text-gray font-weight-lighter">Lot Number</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Service Providers'">
                            <label class="text-gray font-weight-lighter">Rental Type</label>
                            <div class="form-floating">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox1"
                                           value="isDailyRental" formControlName="isDailyRental">
                                    <label class="form-check-label" for="inlineCheckbox1">Daily</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox2"
                                           value="isWeeklyRental" formControlName="isWeeklyRental">
                                    <label class="form-check-label" for="inlineCheckbox2">Weekly</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input mt-1" type="checkbox" id="inlineCheckbox3"
                                           value="isYearlyRental" formControlName="isMonthlyRental">
                                    <label class="form-check-label" for="inlineCheckbox3">Monthly</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Brochure URL"
                                       formControlName="dailyRentalAmount">
                                <label class="text-gray font-weight-lighter">Daily Rental Amount</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Product Video URL"
                                       formControlName="weeklyRentalAmount">
                                <label class="text-gray font-weight-lighter">Weekly Rental Amount</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Product Video URL"
                                       formControlName="monthlyRentalAmount">
                                <label class="text-gray font-weight-lighter">Monthly Rental Amount</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="userType == 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Deposit Amount"
                                       formControlName="depositAmount">
                                <label class="text-gray font-weight-lighter">Deposit Amount</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="userType != 'Farmers' && userType != 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Brochure URL"
                                       formControlName="brochureUrl">
                                <label class="text-gray font-weight-lighter">Brochure URL</label>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3"
                             *ngIf="userType != 'Farmers' && userType != 'Service Providers'">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Product Video URL"
                                       formControlName="productVideoUrl">
                                <label class="text-gray font-weight-lighter">Product Video URL</label>
                            </div>
                        </div>


                        <div class="row mt-3 mb-3">
                            <div class="col-12 text-center d-flex justify-content-center align-content-between">
                                <button type="button" class="btn btn-danger ml-2" (click)="cancelProductUpdate()">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-success ml-5">Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
