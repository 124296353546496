import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {ProductDetails} from "@/model/product/ProductDetails";
import {ImageDetails} from "@/model/asset-details/ImageDetails";
import {FeatureDetails} from "@/model/feature/FeatureDetails";
import {ConfigService} from "@/config.service";
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss']
})

export class ProductDetailComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    productId: number;
    productDetailsById: ProductDetails | undefined;
    productName: string;
    phoneNo: string;
    emailId: string;
    location: string;
    quantityNo: number;
    quantityType: string;
    quantityValue: number;
    description: string;
    unique: string;
    featureId: number;
    featureName: string;
    featureValue: string;
    friendlyValue: string;
    friendlyName: string;
    paymentStatus: string;
    isTrader: string;
    isVerified: string;
    price: number;
    tax: number;
    discount: number;
    netPrice: number;
    productImageList: ImageDetails[] = [];
    featureList: FeatureDetails[] = [];
    endPoint: string;
    isLocalhost = window.location.hostname === 'localhost';
    selectedImageUrl: string;
    imageModalShow = false;
    userType: string;
    editModalShow: boolean = false;
    productFormGroup: UntypedFormGroup;
    userId: number;
    businessRefId: number;
    tags: string[] = [];
    categories: string[] = [];
    farmersTags = ['Organic', 'Non-GMO', 'Grass-fed'];
    farmerCategories = ['Fresh Produce', 'Grains', 'Dairy', 'Livestock'];
    farmInputTags = ['Organic', ' Drought-Resistant'];
    farmInputCategories = ['Seeds', 'Fertilizers', 'Pesticides', 'Machinery'];
    foodProcessorTags = ['Organic', 'Export Grade'];
    foodProcessorCategories = ['Grains', 'Spices', 'Raw Materials', 'Livestock'];
    serviceCategories = ['Machines', 'Vehicles', 'Equipments']
    availableStatus = ['Available', 'Out of Stock', 'Discontinued', 'Archived'];

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private loggingService: LoggingService
    ) {
    }

    getFormGroup(product: any) {
        this.productFormGroup = new UntypedFormGroup({
            productId: new UntypedFormControl(product?.productId || 0, [Validators.required]),
            productName: new UntypedFormControl(product?.productName || '', [Validators.required]),
            friendlyName: new UntypedFormControl(product?.friendlyName || ''),
            description: new UntypedFormControl(product?.description || '', [Validators.required]),
            friendlyDescription: new UntypedFormControl(product?.friendlyDescription || ''),
            discount: new UntypedFormControl(product?.discount || 0),
            tax: new UntypedFormControl(product?.tax || 0),
            grossPrice: new UntypedFormControl(product?.grossPrice || 0, [Validators.required, Validators.min(1)]),
            netPrice: new UntypedFormControl(product?.netPrice || 0, [Validators.required, Validators.min(1)]),
            location: new UntypedFormControl(product?.location || ''),
            userId: new UntypedFormControl(product?.userId || 0),
            userName: new UntypedFormControl(product?.userName || ''),
            phoneNumber: new UntypedFormControl(product?.phoneNumber || '', [Validators.pattern(/^\d{10}$/)]),
            uniqueness: new UntypedFormControl(product?.uniqueness || ''),
            availability: new UntypedFormControl(product?.availability || ''),
            quantityType: new UntypedFormControl(product?.quantityType || ''),
            postedUserType: new UntypedFormControl(product?.postedUserType || ''),
            quantityValue: new UntypedFormControl(product?.quantityValue || 0, [Validators.required, Validators.min(1)]),
            approvalStatus: new UntypedFormControl(product?.approvalStatus || 'Pending'),
            createdBy: new UntypedFormControl(product?.createdBy || 0),
            updatedBy: new UntypedFormControl(product?.updatedBy || 0),
            createdAt: new UntypedFormControl(product?.createdAt || 0),
            updatedAt: new UntypedFormControl(product?.updatedAt || 0),
            referenceId: new UntypedFormControl(product?.referenceId || 0),
            referenceType: new UntypedFormControl(product?.referenceType || ''),
            tags: new UntypedFormControl(product?.tags || ''),
            status: new UntypedFormControl(product?.status || ''),
            category: new UntypedFormControl(product?.category || ''),
            farmingPractices: new UntypedFormControl(product?.farmingPractices || ''),
            farmLocation: new UntypedFormControl(product?.farmLocation || ''),
            brochureUrl: new UntypedFormControl(product?.brochureUrl || ''),
            marketingDescription: new UntypedFormControl(product?.marketingDescription || ''),
            unitMeasure: new UntypedFormControl(product?.unitMeasure || ''),
            harvestDate: new UntypedFormControl(product?.harvestDate || 0),
            packagingType: new UntypedFormControl(product?.packagingType || ''),
            shelfLife: new UntypedFormControl(product?.shelfLife || ''),
            storageConditions: new UntypedFormControl(product?.storageConditions || ''),
            batchNumber: new UntypedFormControl(product?.batchNumber || ''),
            brandName: new UntypedFormControl(product?.brandName || ''),
            manufacturerName: new UntypedFormControl(product?.manufacturerName || ''),
            contactInfo: new UntypedFormControl(product?.contactInfo || ''),
            originCountry: new UntypedFormControl(product?.originCountry || ''),
            cropType: new UntypedFormControl(product?.cropType || ''),
            variety: new UntypedFormControl(product?.variety || ''),
            nutrientComposition: new UntypedFormControl(product?.nutrientComposition || ''),
            applicationRate: new UntypedFormControl(product?.applicationRate || ''),
            form: new UntypedFormControl(product?.form || ''),
            activeIngredient: new UntypedFormControl(product?.activeIngredient || ''),
            toxicityLevel: new UntypedFormControl(product?.toxicityLevel || ''),
            applicationMethod: new UntypedFormControl(product?.applicationMethod || ''),
            modelNumber: new UntypedFormControl(product?.modelNumber || ''),
            powerRating: new UntypedFormControl(product?.powerRating || 0),
            certifications: new UntypedFormControl(product?.certifications || ''),
            registrationNumber: new UntypedFormControl(product?.registrationNumber || ''),
            packagingSize: new UntypedFormControl(product?.packagingSize || ''),
            currency: new UntypedFormControl(product?.currency || 'INR'),
            bulkDiscount: new UntypedFormControl(product?.bulkDiscount || 0),
            stockQuantity: new UntypedFormControl(product?.stockQuantity || 0),
            reorderLevel: new UntypedFormControl(product?.reorderLevel || 0),
            warehouseLocation: new UntypedFormControl(product?.warehouseLocation || ''),
            productVideoUrl: new UntypedFormControl(product?.productVideoUrl || ''),
            productOrigin: new UntypedFormControl(product?.productOrigin || ''),
            grade: new UntypedFormControl(product?.grade || ''),
            lotNumber: new UntypedFormControl(product?.lotNumber || ''),
            moistureContent: new UntypedFormControl(product?.moistureContent || ''),
            purity: new UntypedFormControl(product?.purity || 0, [Validators.min(0), Validators.max(100)]),
            sizeVariants: new UntypedFormControl(product?.sizeVariants || ''),
            minimumOrderQuantity: new UntypedFormControl(product?.minimumOrderQuantity || '1'),
            targetMarket: new UntypedFormControl(product?.targetMarket || ''),
            storageCondition: new UntypedFormControl(product?.storageCondition || ''),
            isDailyRental: new UntypedFormControl(product?.isDailyRental || ''),
            isWeeklyRental: new UntypedFormControl(product?.isWeeklyRental || ''),
            isMonthlyRental: new UntypedFormControl(product?.isMonthlyRental || ''),
            dailyRentalAmount: new UntypedFormControl(product?.dailyRentalAmount || ''),
            weeklyRentalAmount: new UntypedFormControl(product?.weeklyRentalAmount || ''),
            monthlyRentalAmount: new UntypedFormControl(product?.monthlyRentalAmount || ''),
            depositAmount: new UntypedFormControl(product?.monthlyRentalAmount || ''),
        })
    }


    OnSubmit() {
        this.formValidations();
    }

    public productFormErrors = {
        productName: '',
        friendlyName: '',
        description: '',
        phoneNumber: '',
        grossPrice: '',
        netPrice: '',
        quantityValue: ''
    }


    formValidations() {
        this.productFormErrors.productName = ""
        this.productFormErrors.friendlyName = ""
        this.productFormErrors.description = ""
        this.productFormErrors.phoneNumber = ""
        this.productFormErrors.grossPrice = ""
        this.productFormErrors.netPrice = ""
        this.productFormErrors.quantityValue = ""

        let hasError = false;

        if (this.productFormGroup.get("productName")?.invalid) {
            this.productFormErrors.productName = "Product Name is required";
            hasError = true;
        }

        if (this.productFormGroup.get("friendlyName")?.invalid) {
            this.productFormErrors.friendlyName = "Product Friendly Name is required";
            hasError = true;
        }

        if (this.productFormGroup.get("description")?.invalid) {
            this.productFormErrors.description = "Description is required";
            hasError = true;
        }

        if (this.productFormGroup.get("phoneNumber")?.invalid) {
            if (this.productFormGroup.get("phoneNumber")?.errors?.['required']) {
                this.productFormErrors.phoneNumber = "Phone Number is required";
            } else if (this.productFormGroup.get("phoneNumber")?.errors?.['pattern']) {
                this.productFormErrors.phoneNumber = "Phone Number must be 10 digits";
            }
            hasError = true;
        }

        if (this.productFormGroup.get("grossPrice")?.invalid) {
            if (this.productFormGroup.get("grossPrice")?.errors?.required) {
                this.productFormErrors.grossPrice = "Gross Price is required";
            } else if (this.productFormGroup.get("grossPrice")?.errors?.min) {
                this.productFormErrors.grossPrice = "Gross Price must be greater than 0";
            }
            hasError = true;
        }

        if (this.productFormGroup.get("netPrice")?.invalid) {
            if (this.productFormGroup.get("netPrice")?.errors?.required) {
                this.productFormErrors.netPrice = "Net Price is required";
            } else if (this.productFormGroup.get("netPrice")?.errors?.min) {
                this.productFormErrors.netPrice = "Net Price must be greater than 0";
            }
            hasError = true;
        }

        if (this.productFormGroup.get("quantityValue")?.invalid) {
            if (this.productFormGroup.get("quantityValue")?.errors?.required) {
                this.productFormErrors.quantityValue = "Quantity is required";
            } else if (this.productFormGroup.get("quantityValue")?.errors?.min) {
                this.productFormErrors.quantityValue = "Quantity must be greater than 0";
            }
            hasError = true;
        }

        if (!hasError) {
            console.log('update product form group', this.productFormGroup.value);
            /*this.updateProductDetail();*/
        }
    }

    ngOnInit() {
        this.getFormGroup(this.productDetailsById)
        this.getProductDetails();
        window.scrollTo(0, 0);
    }

    openModal(image: any) {
        this.selectedImageUrl = `${this.endPoint}/api/image-details/get/${image.imageId}`;
        this.imageModalShow = true;
    }


    allowOnlyNumbersWithOneDot(event: KeyboardEvent): void {
        const inputChar = event.key;
        const currentValue = (event.target as HTMLInputElement).value;

        if (!/^[0-9]*\.?[0-9]*$/.test(currentValue + inputChar) && inputChar !== 'Backspace') {
            event.preventDefault();
        }
    }

    allowOnlyNumbers(event: KeyboardEvent): void {
        const charCode = event.charCode;
        // Allow only numeric characters (0-9)
        if (charCode < 48 || charCode > 57) {
            event.preventDefault(); // Prevent input if it's not a number
        }
    }


    // When return any Page Remove ProductID from Local Storage
    ngOnDestroy() {
        localStorage.removeItem('productId');
    }

    getImageUrl(imageId: string): string {
        return `${this.endPoint}/api/image-details/get/${imageId}`;
    }


    // Click a view button Product ID based Product Get Functionality
    getProductDetails() {
        this.spinner.show();
        this.productId = Number(localStorage.getItem('productId'));
        this.appService
            .getProductDetailsById(this.productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    this.productDetailsById = data;
                    this.getFormGroup(this.productDetailsById);
                    if (!environment.production && this.isLocalhost) {
                        console.log("product detail::", this.productDetailsById);
                    }
                    console.log("product detail::", this.productDetailsById);
                    this.endPoint = this.configService.getConfig().END_POINT;
                    const product = this.productDetailsById;
                    this.productImageList = product.productImageList;
                    this.productId = product.productId;
                    this.productName = product.productName;
                    this.phoneNo = product.phoneNumber;
                    this.location = product.location;
                    this.quantityType = product.quantityType;
                    this.quantityValue = product.quantityValue;
                    this.description = product.description;
                    this.unique = product.uniqueness;
                    this.isVerified = product.approvalStatus;
                    this.featureList = product.productFeatureList;
                    this.price = product.grossPrice;
                    this.tax = product.tax;
                    this.discount = product.discount;
                    this.netPrice = product.netPrice;
                    this.userType = product?.userDetails?.enrollmentType || product.postedUserType;
                    this.userId = product?.userId;
                    this.businessRefId = product?.referenceId;
                    if (this.userType == 'Farmers' || this.userType == 'FPO') {
                        this.tags = this.farmersTags;
                        this.categories = this.farmerCategories;
                    } else if (this.userType == 'Farmer Input') {
                        this.tags = this.farmInputTags;
                        this.categories = this.farmInputCategories;
                    } else if (this.userType == 'Food Processors') {
                        this.tags = this.foodProcessorTags;
                        this.categories = this.foodProcessorCategories;
                    } else if (this.userType == 'Service Providers') {
                        this.tags = this.foodProcessorTags;
                        this.categories = this.serviceCategories;
                    }
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                })
    }


    /*Product ID based Product status Approval*/
    approveStatus(approvalStatus: string, productDetailsId: number) {
        const productStatus = {
            productId: productDetailsId,
            approvalStatus: approvalStatus
        }
        this.spinner.show();
        this.appService
            .updateProductStatus(productStatus)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log("update product status::", data)
                    }
                    this.toastr.success('Product status updated');
                    this.productDetailsById.approvalStatus = data.approvalStatus;
                    this.getProductDetails();
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
    }


    editProductDetails() {
        this.editModalShow = true;
    }


    updateProductDetail() {
        this.spinner.show();
        this.appService.updateProductDetails(this.userId, this.businessRefId, this.productFormGroup.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    this.editModalShow = false;
                    if (!environment.production && this.isLocalhost) {
                        console.log("update product data::", this.productDetailsById);
                    }
                    this.toastr.success('Product details updated successfully');
                    this.getProductDetails();
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();
                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });

    }

    cancelProductUpdate() {
        this.editModalShow = false;
        this.toastr.info('You canceled the update of product details');
    }

    getFormattedHarvestDate(harvestDate: number | undefined): string {
        if (harvestDate === 0 || harvestDate == null) {
            return '-';
        }

        const epochLength = harvestDate.toString().length;

        // If length is 10, multiply by 1000 (seconds to milliseconds)
        const dateInMillis = epochLength === 10 ? harvestDate * 1000 : harvestDate;

        // Format the date
        return new Date(dateInMillis).toLocaleDateString('en-GB'); // 'en-GB' for 'dd-MM-yyyy'
    }

}