import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";
import {catchError, EMPTY, Subject, takeUntil, tap} from 'rxjs';
import {UserDetails} from "@/model/user-details/UserDetails";
import {ConfigService} from "@/config.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})

export class UserManagementComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    userDetailsList: UserDetails [] = [];
    tableUserDetailsList: UserDetails [] = [];
    regDetailsList: UserDetails [] = [];
    endPoint: string;
    userTrader: string;
    imageId: number;
    vehicleForm: FormGroup;
    userTypeFilter: string | null = null;
    userType: String[] = [];
    userDateFilter: string | null = null;
    date: String[] = [];
    userLocationFilter: string | null = null;
    userLocation: String[] = [];
    isLocalhost = window.location.hostname === 'localhost';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private loggingService: LoggingService,
    ) {
        this.vehicleForm = this.formBuilder.group({
            vehicleNumber: ['', [Validators.required]]
        });
    }

    ngOnInit() {
        this.getAllRegisteredDetailsList();
        this.endPoint = this.configService.getConfig().END_POINT;
    }

    getFormattedDate(date: number | undefined): string {
        if (date === 0 || date == null) {
            return '-';
        }

        const epochLength = date.toString().length;

        // If length is 10, multiply by 1000 (seconds to milliseconds)
        const dateInMillis = epochLength === 10 ? date * 1000 : date;

        // Format the date
        return new Date(dateInMillis).toLocaleDateString('en-GB'); // 'en-GB' for 'dd-MM-yyyy'
    }


    /*After Get all Registered Details based Each View Button added
    When Click the View button, that UserRegId based User Register Detail show page*/
    viewProfile(id: number) {
        localStorage.setItem("userRegId", String(id));
        this.router.navigate(['./main/verify-profile']);
    }

    selectOrderType(event: any) {
        const value = event.target.value;
        this.tableUserDetailsList = this.userDetailsList.filter(orderType => orderType.enrollmentType === value);
        if (value == 'All') {
            this.tableUserDetailsList = this.userDetailsList;
        }
    }


    /*Select User Type, Date & Location event and filter Type Select Functionality*/
    userFilter(event: any, filterType: string) {
        const value = event.target.value;
        if (value != 'All') {
            switch (filterType) {
                case 'userType' :
                    this.userTypeFilter = value !== 'Select User Type' ? value : null;
                    break;
                case 'date' :
                    this.userDateFilter = value !== 'Select Date' ? value : null;
                    break;
                case 'location' :
                    this.userLocationFilter = value !== 'Select User Location' ? value : null;
                    break;
                default:
                    break;
            }
            this.applyUserFilter();
        } else {
            this.tableUserDetailsList = this.userDetailsList;
        }
    }


    // User Type, Date, City based Filter Functionality
    applyUserFilter() {
        const userFilter = this.userTypeFilter ? this.userDetailsList.filter(userType => userType.enrollmentType === this.userTypeFilter) : this.regDetailsList;
        const date = this.userDateFilter ? userFilter.filter(date => {
            const options: Intl.DateTimeFormatOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
            const formattedDate = new Date(date.createdAt).toLocaleDateString('en-GB', options);
            return formattedDate === this.userDateFilter;
        }) : userFilter;
        const locationCity = date.filter(location => location.userAddress?.length > 0)
        this.tableUserDetailsList = this.userLocationFilter ? locationCity.filter(location => location.userAddress[0].city.toLowerCase() === this.userLocationFilter.toLowerCase()) : date;
        if (!environment.production && this.isLocalhost) {
            console.log('Filter Data::', this.tableUserDetailsList);
        }
    }


    /* Get all Registration Details List*/
    getAllRegisteredDetailsList() {
        this.spinner.show();

        this.appService.getAllUserRegisteredDetails()
            .pipe(
                takeUntil(this.destroy$),
                tap((data) => {
                    //Create a array for Enrollment Type and remove Duplicate value from enrollmentType.
                    this.userType = Array.from(new Set(data.map(type => type.enrollmentType))).sort((a, b) => a.localeCompare(b));
                    //Create a array for createAt(Date EPOCH value) and remove Duplicate value from createdAt.
                    const uniqueDates = Array.from(new Set(data.map(item => item.createdAt)));
                    //Convert into Epoch value to Normal Date
                    const localDate = uniqueDates.map(epoch => this.datePipe.transform(epoch, 'dd/MM/yyyy'));
                    // After Convert Normal Date remove Duplicate.
                    this.date = Array.from(new Set(localDate));
                    this.date.sort((a, b) => {
                        // Parse the dates to a comparable format
                        const dateA = new Date(a.split('/').reverse().join('/')).getTime();
                        const dateB = new Date(b.split('/').reverse().join('/')).getTime();
                        return dateB - dateA; // Descending order
                    });
                    //Location Data filter if address list greater than 0
                    const location = data.filter(location => location.userAddress?.length > 0);
                    //User Location List remove Duplicate From address and First Address filter
                    this.userLocation = Array.from(new Set(location.map(city => city.userAddress[0].city.toLowerCase())));
                    if (!environment.production && this.isLocalhost) {
                        console.log('Date Filter::', this.date);
                        console.log('Location File', this.userLocation)
                    }
                }),
                catchError(error => {
                    console.error('Error fetching user details:', error);
                    this.spinner.hide();
                    this.toastr.error(
                        error.error || error.error.message
                            ? error.error.message
                            : 'Something went wrong, please try again'
                    );
                    return EMPTY;
                })
            )
            .subscribe(data => {
                this.spinner.hide();
                this.userDetailsList = data;
                this.tableUserDetailsList = this.userDetailsList.sort((a, b) => b.userRegistrationId - a.userRegistrationId);
                this.regDetailsList = this.tableUserDetailsList;
                if (!environment.production && this.isLocalhost) {
                    console.log('Registered List:', this.userDetailsList);
                }
            });
    }
}