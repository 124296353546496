import { DashBoardData } from '@/model/pieChart/dashboardData';
import { PieChart } from '@/model/pieChart/pieChart';
import { UserDetails } from '@/model/user-details/UserDetails';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { Chart, registerables } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  // api values
  pieChartData: PieChart[] = []
  newUserChartData: DashBoardData[] = [];
  paymentPendingChartData: DashBoardData[] = []
  profilePendingChartData: DashBoardData[] = []
  expiryNotificationsData: DashBoardData[] = []

  // nodata values
  pieChartOne: boolean = true;
  pieChartTwo: boolean = true;
  pieChartThree: boolean = true;
  pieChartFour: boolean = true;

  // table or chart design values
  FirstCardDesign: boolean = true;
  secondCardDesign: boolean = true;
  ThirdCardDesign: boolean = true;
  FourthCardDesign: boolean = true;


  // card data
  fullData: UserDetails[] = [];
  newUsersData: UserDetails[] = [];
  profilePending: UserDetails[] = [];
  paymentPendingData: UserDetails[] = [];
  subscriptionEndDate: UserDetails[] = [];
  // newUsersData:UserDetails[]=[];


  // collapse using for card open and close
  isCollapsed: boolean[] = Array().fill(true);


  // design Change value
  designChangeValue: string = "NEWUSER";

  //  table-title
  title: string = "New User Details";

  // Tn-map
  userLength: number = 0

  // screen-width
  isMobile: boolean = false;

  // color
  backgroundColors = [
    'rgba(86, 226, 207, 0.3)',  // #56E2CF
    'rgba(86, 174, 226, 1)',    // #56AEE2
    'rgba(86, 104, 226, 1)',    // #5668E2
    'rgba(138, 86, 226, 1)',    // #8A56E2
    'rgba(207, 86, 226, 1)',    // #CF56E2
    'rgba(226, 86, 174, 1)',    // #E256AE
    'rgba(226, 86, 104, 1)',    // #E25668
    'rgba(226, 137, 86, 1)',    // #E28956
    'rgba(226, 207, 86, 1)',    // #E2CF56
    'rgba(174, 226, 86, 1)',    // #AEE256
    'rgba(104, 226, 86, 1)',    // #68E256
    'rgba(86, 226, 137, 1)'     // #56E289
  ];

  borderColor = [
    'rgba(86, 226, 207, 0.3)',  // #56E2CF
    'rgba(86, 174, 226, 1)',    // #56AEE2
    'rgba(86, 104, 226, 1)',    // #5668E2
    'rgba(138, 86, 226, 1)',    // #8A56E2
    'rgba(207, 86, 226, 1)',    // #CF56E2
    'rgba(226, 86, 174, 1)',    // #E256AE
    'rgba(226, 86, 104, 1)',    // #E25668
    'rgba(226, 137, 86, 1)',    // #E28956
    'rgba(226, 207, 86, 1)',    // #E2CF56
    'rgba(174, 226, 86, 1)',    // #AEE256
    'rgba(104, 226, 86, 1)',    // #68E256
    'rgba(86, 226, 137, 1)'     // #56E289
  ];


  AlterBackgroundColors = [
    'rgba(86, 174, 226, 1)',    // #56AEE2
    'rgba(226, 207, 86, 1)',    // #E2CF56
    'rgba(226, 86, 174, 1)',    // #E256AE
    'rgba(86, 104, 226, 1)',    // #5668E2
    'rgba(138, 86, 226, 1)',    // #8A56E2
    'rgba(207, 86, 226, 1)',    // #CF56E2
    'rgba(226, 137, 86, 1)',    // #E28956
    'rgba(226, 86, 104, 1)',    // #E25668
    'rgba(174, 226, 86, 1)',    // #AEE256

    'rgba(104, 226, 86, 1)',    // #68E256
    'rgba(86, 226, 137, 1)',   // #56E289
    'rgba(86, 226, 207, 0.3)',  // #56E2CF


  ];

  AlterBorderColor = [
    'rgba(86, 174, 226, 1)',    // #56AEE2
    'rgba(226, 207, 86, 1)',    // #E2CF56
    'rgba(226, 86, 174, 1)',    // #E256AE
    'rgba(86, 104, 226, 1)',    // #5668E2
    'rgba(138, 86, 226, 1)',    // #8A56E2
    'rgba(207, 86, 226, 1)',    // #CF56E2
    'rgba(226, 137, 86, 1)',    // #E28956
    'rgba(226, 86, 104, 1)',    // #E25668
    'rgba(174, 226, 86, 1)',    // #AEE256

    'rgba(104, 226, 86, 1)',    // #68E256
    'rgba(86, 226, 137, 1)',   // #56E289
    'rgba(86, 226, 207, 0.3)',  // #56E2CF

  ];





  constructor(
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.getChartValues()
    this.getAllUserDetails()
  };



  toggleCollapse(number: number): void {
    this.isCollapsed[number] = !this.isCollapsed[number]; // Toggle the collapse state.
  }

  designChange(name: string) {
    if (name == 'Table') {
      this.FirstCardDesign = false;
    } else {
      this.FirstCardDesign = true;
    }
  }

  designChangeTwo(name: string) {
    if (name == 'Table') {
      this.secondCardDesign = false;
    } else {
      this.secondCardDesign = true;
    }
  }

  designChangeThree(name: string) {
    if (name == 'Table') {
      this.ThirdCardDesign = false;
    } else {
      this.ThirdCardDesign = true;
    }
  }


  designChangeFour(name: string) {
    if (name == 'Table') {
      this.FourthCardDesign = false;
    } else {
      this.FourthCardDesign = true;
    }
  }





  getChartValues() {
    this.appService.getPieChartValues()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log('chart data::', data);

        const NewUser = data.find(type => type.chartType === 'NEW_USERS');
        this.newUserChartData = NewUser.dashboardData;
        this.newUserChartData.length <= 0 ? this.pieChartOne = false : null


        const Payment = data.find(type => type.chartType === 'PAYMENT_PENDING');
        this.paymentPendingChartData = Payment.dashboardData;
        this.paymentPendingChartData.length <= 0 ? this.pieChartTwo = false : null

        const Profile = data.find(type => type.chartType === 'PROFILE_PENDING');
        this.profilePendingChartData = Profile.dashboardData;
        this.profilePendingChartData.length <= 0 ? this.pieChartThree = false : null

        const Expiry = data.find(type => type.chartType === 'EXPIRY_NOTIFICATION');
        this.expiryNotificationsData = Expiry.dashboardData;
        this.expiryNotificationsData.length <= 0 ? this.pieChartFour = false : null

        this.check();
      }, (error) => {
        console.log('Error in getting pie chart values::', error);

      });
  }








  check(): void {
    Chart.register(...registerables);
    console.log('register');
    const ctx = document.getElementById('myBarChart') as HTMLCanvasElement;

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.newUserChartData.map((item) => item.label), // Extract labels dynamically
        datasets: [
          {
            label: 'Register Analytics', // Corrected label spelling
            data: this.newUserChartData.map((item) => item.count), // Extract data dynamically
            backgroundColor: this.backgroundColors.slice(0, this.newUserChartData.length), // Ensure sufficient colors
            borderColor: this.borderColor.slice(0, this.newUserChartData.length), // Ensure sufficient border colors
            borderWidth: 1, // Set border width
          },
        ],
      },
      options: {
        responsive: true, // Make chart responsive
        maintainAspectRatio: false, // Allow flexible dimensions
        plugins: {
          legend: {
            display: true, // Display legend
            position: 'top', // Position of the legend
          },
          tooltip: {
            enabled: true, // Enable tooltips
          },
        },
      },
      plugins: [
        {
          id: 'valueAboveBars',
          afterDatasetsDraw: (chart) => {
            const { ctx, data } = chart;
            chart.data.datasets.forEach((dataset, i) => {
              const meta = chart.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                const value = dataset.data[index] as number;
                ctx.fillStyle = '#000'; // Text color
                ctx.font = '12px Arial';
                ctx.textAlign = 'center';
                ctx.fillText(value.toString(), bar.x, bar.y - 5); // Position text above the bar
              });
            });
          },
        },
      ],
    });






    const two = document.getElementById('myDoughnutChart') as HTMLCanvasElement;

    new Chart(two, {
      type: 'doughnut',
      data: {
        labels: this.paymentPendingChartData.map(item => item.label),
        datasets: [{
          data: this.paymentPendingChartData.map(item => item.count),
          borderWidth: 1,
          backgroundColor: this.backgroundColors.slice(0, this.newUserChartData.length),
          borderColor: this.borderColor.slice(0, this.newUserChartData.length),
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset;
                const value = dataset.data[tooltipItem.dataIndex];
                const total = dataset.data.reduce((a, b) => a + b, 0);
                const percentage = ((value / total) * 100).toFixed(2);
                return `${tooltipItem.label}: ${value} (${percentage}%)`;
              }
            }
          }
        }
      }
    });



    const pie = document.getElementById('myChartPie') as HTMLCanvasElement;

    new Chart(pie, {
      type: 'pie',
      data: {
        labels: this.profilePendingChartData.sort((a, b) => b.label.localeCompare(a.label)).map(it => it.label),
        datasets: [{
          data: this.profilePendingChartData.map(it => it.count),
          borderWidth: 1,
          hoverOffset: 5,
          backgroundColor: this.AlterBackgroundColors.slice(0, 6),
          borderColor: this.AlterBorderColor.slice(0, 6),
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset;
                const value = dataset.data[tooltipItem.dataIndex]
                const label = tooltipItem.label;
                const total = dataset.data.reduce((a, b) => a + b, 0);
                const percentage = ((value / total) * 100).toFixed(2)
                return `${label}: ${value} (${percentage}%)`
              }
            }
          }
        }
      }
    });






    const three = document.getElementById('myLineChart') as HTMLCanvasElement;

    new Chart(three, {
      type: 'bar',
      data: {
        labels: this.expiryNotificationsData.map((value) => value.label), // Common labels for both datasets
        datasets: [
          {
            label: 'Chart 1',
            data: this.expiryNotificationsData.map((value) => value.count),
            backgroundColor: this.backgroundColors,
            borderColor: this.borderColor,
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    
  }






  getAllUserDetails() {
    this.spinner.show();
    this.appService.getAllUserRegisteredDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.fullData = data;
        this.newUsersData = data.filter(item => item.accountStatus == 'INACTIVE').sort((a,b)=> b.userRegistrationId - a.userRegistrationId);

        this.profilePending = data.filter(item => item.businessInformation == 0 && item.accountStatus == 'ACTIVE').sort((a,b)=> b.userRegistrationId - a.userRegistrationId);
        this.paymentPendingData = data.filter(item => item.paymentStatus == '' && item.accountStatus == 'ACTIVE').sort((a,b)=> b.userRegistrationId - a.userRegistrationId);
        this.subscriptionEndDate = this.getUsersWithSubscriptionEndingSoon(data);

      }, (err: any) => {
        console.log('user-data-error', err);
      });
  }




  /**
   * This function filters an array of user data to find those whose subscription is ending within the
   * next 30 days.
   * @param {any[]} data - The `data` parameter is an array containing information about users and
   * their subscription end dates. Each item in the array has a `subscriptionEndDate` property that
   * represents the end date of the user's subscription.
   * @returns The function `getUsersWithSubscriptionEndingSoon` is returning an array of items from the
   * `data` array where the `subscriptionEndDate` falls within the next 30 days from the current time.
   * Each item in the returned array will have its `subscriptionEndDate` formatted as a localized date
   * string in the 'en-GB' format.
   */
  private getUsersWithSubscriptionEndingSoon(data: any[]) {
    // Get the current time in milliseconds since the Unix epoch
    const currentTime = Date.now();

    // Calculate the time for 30 days from now in milliseconds
    const thirtyDaysLater = currentTime + 30 * 24 * 60 * 60 * 1000;

    // Filter the input array to find users whose subscriptions end within the next 30 days
    return data.filter(item => item.subscriptionEndDate >= currentTime && item.subscriptionEndDate <= thirtyDaysLater).sort((a,b)=> b.userRegistrationId - a.userRegistrationId);

  }






  designSet(value: string) {

    this.designChangeValue = value;
    switch (value) {
      case 'NEWUSER':
        this.title = 'New User Details';
        break;
      case 'PROFILE':
        this.title = 'Profile Pending Details';
        break;
      case 'PAYMENT':
        this.title = 'Payment Pending Details';
        break;
      case 'SUBSCRIPTION':
        this.title = 'Subscription Expiry Details';
        break;

    }
  }


  navigateApprove(regId: number) {
    localStorage.setItem("userRegId", String(regId));
    this.router.navigate(['./main/verify-profile']);
  }



  animateCount(target: number) {
    let count = 0;
    const interval = setInterval(() => {
      if (count < target) {
        count += Math.ceil(target / 10);  // Incrementing by steps (like 1, 5, 10, etc.)
        if (count > target) count = target; // Ensuring it does not exceed the target
        return count;
      } else {
        clearInterval(interval);  // Stop the animation once it reaches the target
      }
    }, 100); // Adjust the interval time (100ms) for smoother or faster animation
  }






  // Update tooltip position and value dynamically
  updateTooltipPosition(district: string) {
    const District = this.fullData.filter(item =>
      item.location == district);
    this.userLength = District.length;
  }





  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isMobile = window.innerWidth < 768; // Assuming mobile is below 768px
  }







}
















