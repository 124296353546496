<section class="content">
    <div class="container-fluid ml-lg-3 ml-2 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="row mb-3">
                        <div class="col-lg-3 col-md-5 mt-2">
                            <h3 class="fw-bolder mt-4">Personal Details <img class="img-fluid ml-3" alt="clear"
                                                                             src="assets/img/pen-circle.png" height="32"
                                                                             width="32" type="button"
                                                                             (click)="editUserProfileDetails()"
                                                                             matTooltip="click to edit"></h3>
                        </div>
                        <div class="col-lg-5 col-md-7 mt-2">
                            <!-- <div>
                                 <div class="row" *ngIf="isActive != 'ACTIVE'">
                                     <div class="col-md-4 col-lg-4 mt-3">
                                         <button class="btn-success-green btn-block p-3"
                                             (click)="approveStatus('VERIFIED', regId)">
                                             Approve
                                         </button>
                                     </div>
                                     <div class="col-md-4 col-lg-4 mt-3">
                                         <button class="btn-danger-red btn-block p-3"
                                             (click)="approveStatus('REJECTED', regId)">Reject
                                         </button>
                                     </div>
                                     <div class="col-md-4 col-lg-4 mt-3">
                                         <button class="btn-secondary-light btn-block p-3"
                                             (click)="approveStatus('NON_VERIFIED', regId)">Hold
                                         </button>
                                     </div>
                                 </div>
                             </div>-->
                        </div>
                        <div class="col-lg-4 col-md-12 mt-2">
                            <div class="row">
                                <div class="col-md-3 col-lg-4 mt-3">
                                    <span class="badge-primary-blue btn-block text-center"
                                          *ngIf="isTrader == 'TRADERS'">Trader</span>
                                </div>
                                <div class="col-md-3 col-lg-4 mt-3" *ngIf="this.paymentStatus != null">
                                    <span class="badge-primary-blue btn-block text-center p-3">{{paymentStatus}}</span>
                                </div>
                                <div class="col-md-3 col-lg-4 mt-3" *ngIf="isActive == 'ACTIVE'">
                                    <span class="badge-success-green text-center btn-block p-3">Approved</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3" *ngIf="isActive != 'ACTIVE'">
                        <div class="col-lg-6 col-xl-6 col-12">
                            <div class="row mt-3">
                                <div class="col-lg-4 col-xl-4 col-12 col-md-4">
                                    <div class="form-check mt-3">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                               id="flexRadioDefault1" value="WITH_SUBSCRIPTION"
                                               (input)="onSelectSubscription($event , 'WITH_SUBSCRIPTION')">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            With Subscription
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-12 col-md-4">
                                    <div class="form-check mt-3">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                               id="flexRadioDefault2" value="WITHOUT_SUBSCRIPTION"
                                               (input)="onSelectSubscription($event , 'WITHOUT_SUBSCRIPTION')">
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Without Subscription
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-3 col-12 col-md-4" *ngIf="isWithoutSubscription">
                                    <div class="form-floating">
                                        <input type="date" class="form-control" id="floatingInput"
                                               placeholder="Subscription End Date"
                                               (change)="onSelectSubscriptionEndDate($event)">
                                        <label for="floatingInput">Subscription End Date</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="row">
                                <div class="col-md-4 col-lg-4 mt-3" *ngIf="isApproveButtonShow">
                                    <button class="btn-success-green btn-block p-3"
                                            (click)="approveStatus('VERIFIED', regId)">
                                        Approve
                                    </button>
                                </div>
                                <div class="col-md-4 col-lg-4 mt-3">
                                    <button class="btn-danger-red btn-block p-3"
                                            (click)="approveStatus('REJECTED', regId)">Reject
                                    </button>
                                </div>
                                <div class="col-md-4 col-lg-4 mt-3">
                                    <button class="btn-secondary-light btn-block p-3"
                                            (click)="approveStatus('NON_VERIFIED', regId)">Hold
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-8 mt-2">
                        <div class="row">
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Name</p>
                                    <span class="mt-0 fw-bold">{{name || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{phoneNo || '-'}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Email</p>
                                    <span class="mt-0 fw-bold">{{emailId || '-'}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Self Description</p>
                                    <span class="mt-0 fw-bold">{{description || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Door No</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.doorNo || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Street Name</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.streetName || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Area</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.area || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">City</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.city || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">State</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.state || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Pin Code</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.pinCode || '-'}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Land Mark</p>
                                    <span class="mt-0 fw-bold">{{userDetailsById?.userAddress[0]?.landmark || '-'}}</span>
                                </div>
                            </div>
                            <div class="row mt-4 ml-2" *ngIf="traderDetail">
                                <div class="col-12">
                                    <h4 class="fw-bolder">Trader Details</h4>
                                </div>
                            </div>
                            <div class="row" *ngIf="traderDetail">
                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Company Name</p>
                                        <span class="mt-0 fw-bold">{{companyName || '-'}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Registered Address</p>
                                        <span class="mt-0 fw-bold">{{displayAddress || '-'}}</span>
                                    </div>
                                </div>

                                <!--<div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Display Address</p>
                                        <span class="mt-0 fw-bold">{{displayAddress}}</span>
                                    </div>
                                </div>-->
                            </div>
                        </div>

                        <!-- <div class="row"
                             *ngIf="this.userDetailsById?.enrollmentType == 'Farmers' || this.userDetailsById?.enrollmentType == 'FPO' || this.userDetailsById?.enrollmentType == 'Service Provider'">
                            <div class="row mt-3 mb-3">
                                <div class="col-12">
                                    <h4 class="fw-bolder mt-2 mb-2" *ngIf="this.userDetailsById?.enrollmentType == 'Farmers'">Farm
                                        Details  <img
                                        class="img-fluid ml-3" alt="clear" src="assets/img/pen-circle.png" height="32"
                                        width="32" type="button" (click)="editFarmsDetails(this.userDetailsById?.userRegistrationId,this.userDetailsById?.farmDetails[0]?.farmDetailsId,
                                            this.userDetailsById?.enrollmentType)"
                                        matTooltip="click to edit"></h4>
                                    <h4 class="fw-bolder mt-2 mb-2"
                                        *ngIf="this.userDetailsById?.enrollmentType == 'FPO' || this.userDetailsById?.enrollmentType == 'Service Provider'">{{this.userDetailsById?.enrollmentType}}
                                        Details <img
                                        class="img-fluid ml-3" alt="clear" src="assets/img/pen-circle.png" height="32"
                                        width="32" type="button" (click)="editFarmsDetails(this.userDetailsById?.userRegistrationId,this.userDetailsById?.farmDetails[0]?.farmDetailsId,
                                            this.userDetailsById?.enrollmentType)"
                                        matTooltip="click to edit"></h4>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="this.userDetailsById?.enrollmentType == 'Farmers'">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Primary Crops Grown</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.farmDetails[0]?.primaryCropsGrown }}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2"
                                     *ngIf="this.userDetailsById?.enrollmentType != 'Service Provider'">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">FPO Name</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.farmDetails[0]?.fpoName }}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="this.userDetailsById?.enrollmentType == 'Farmers'">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">FPO Link Name</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.farmDetails[0]?.fpoLinkName}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2" *ngIf="this.userDetailsById?.enrollmentType == 'FPO'">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">FPO Registration Number</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.farmDetails[0]?.fpoRegistrationNumber}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2"
                                     *ngIf="this.userDetailsById?.enrollmentType == 'Service Provider'">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Business Name</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.businessInformation[0]?.businessName}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2"
                                     *ngIf="this.userDetailsById?.enrollmentType == 'Service Provider'">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Service Type</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.businessInformation[0]?.typeOfService}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Location</p>
                                        <span class="mt-0 fw-bold">{{this.userDetailsById?.userAddress[0]?.city}}</span>
                                    </div>
                                </div>


                            </div>
                        </div> -->
                    </div>
                    <div class="col-lg-4 mt-2">
                        <div class="row mt-2">
                            <div class="row mt-2 mb-1">
                                <p class="mb-0">Validation Proof</p>
                            </div>
                            <div class="row mt-3 mb-3">
                                <div class="col-lg-4 col-6 col-md-4 mt-2"
                                     *ngFor="let image of this.verificationImageList">
                                    <img *ngIf="image?.imageId"
                                         src="{{this.endPoint}}/api/image-details/get/{{image.imageId}}"
                                         class="img-fluid" loading="lazy" (click)="openModal(image)">
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2" *ngIf="traderDetail">
                            <div class="row mt-2 mb-1">
                                <p class="mb-0">Trader Pictures</p>
                            </div>
                            <div class="row mt-3 mb-3">
                                <div class="col-lg-4 col-6 col-md-4 mt-2" *ngFor="let image of this.traderImageList">
                                    <img *ngIf="image?.imageId"
                                         src="{{endPoint}}/api/image-details/get/{{image.imageId}}" class="img-fluid"
                                         loading="lazy" (click)="openModal(image)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="container-fluid" *ngIf="userProductsList.length > 0">
            <div class="row mb-3">
                <div class="col-12">
                    <h4 class="fw-bolder mt-2 mb-2">{{name}} Products</h4>
                </div>
            </div>
            <div class="row">
                <div class="content-card mb-4">
                    &lt;!&ndash;<mat-card class="col-lg-3 mb-2 mt-3 example-card" *ngFor="let product of this.productDetailsList">&ndash;&gt;
                    <mat-card class="items" *ngFor="let product of this.userProductsList">
                        <div class="d-flex flex-column h-100">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image">
                                    <h5 class="icon-circle"><h5
                                            class="fw-bolder text-uppercase text-center text-white mt-2">{{product.productName.charAt(0).toUpperCase()}}</h5>
                                    </h5>
                                </div>
                                <mat-card-title class="fw-bold"><h6>{{product.productName}}</h6></mat-card-title>
                                <mat-card-subtitle class="text-black">{{product.friendlyName}}</mat-card-subtitle>
                            </mat-card-header>
                            <img mat-card-image class="flex-grow-1 object-fit-contain"
                                 *ngIf="product.productImageList?.length > 0 && product.productImageList[0].imageId"
                                 [src]="endPoint + '/api/image-details/get/' + product.productImageList[0].imageId"
                                 alt="image" loading="lazy">
                            &lt;!&ndash; <mat-card-content class="flex-grow-1">

                             </mat-card-content>
                             <mat-card-actions>
                                &lt;!&ndash; <button class="btn-inactive mr-md-4 mt-1" (click)="viewProfile(product.productId)">View</button>
                                 <span [ngClass]="{'badge-active': product.approvalStatus === 'VERIFIED', 'badge-inactive': product.approvalStatus !== 'VERIFIED'}"
                                       class="text-white mt-1">{{product.approvalStatus}}</span>&ndash;&gt;
                             </mat-card-actions>&ndash;&gt;
                            <mat-card-actions>

                            </mat-card-actions>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>-->

        <div class="container-fluid mt-3" *ngIf="this.isErrorShow == 'Farmers' || this.isErrorShow == 'FPO'">
            <div class="row">
                <div class="col-12">
                    <h4 class="fw-bolder mt-2">Farms
                        <button class="btn btn-outline-dark btn-sm"
                                (click)="editFarmsDetails(this.userDetailsById?.userRegistrationId,0,this.userDetailsById?.enrollmentType)">
                            Add
                            New
                            <i class="fa fa-plus-circle fw-bold" matTooltip="Click to add"></i>
                        </button>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="card direct-chat direct-chat-primary" *ngFor="let farms of this.farmDetailsList">
                    <div class="card-header bg-white">
                        <h6 class="card-title mt-2">{{farms?.primaryCropsGrown || '-' }}</h6>
                        <h6 class="text-end">
                            <button class="btn btn-outline-danger btn-sm" (click)="editFarmsDetails(this.userDetailsById?.userRegistrationId,farms?.farmDetailsId,
                                this.userDetailsById?.enrollmentType)">Edit <i class="fa fa-pen" alt="clear" width="32"
                                                                               type="button"
                                                                               matTooltip="click to edit"></i></button>
                        </h6>
                        <div class="card-tools">

                        </div>
                    </div>
                    <div class="card-body">
                        <div class="direct-chat-messages overflow-x-hidden">
                            <div class="row">

                                <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="farms?.enrollmentType == 'Farmers'">
                                    <p class="mb-0">Farm Size</p>
                                    <span class="mt-0 fw-bold">{{ farms?.farmSize || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="farms?.enrollmentType == 'Farmers'">
                                    <p class="mb-0">Primary Crops Grown</p>
                                    <span class="mt-0 fw-bold">{{ farms?.primaryCropsGrown || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="farms.enrollmentType == 'FPO'">
                                    <p class="mb-0">Number of Members</p>
                                    <span class="mt-0 fw-bold">{{ farms?.primaryCropsGrown || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{ farms?.contactNumber || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3" *ngIf="farms?.enrollmentType == 'Farmers'">
                                    <p class="mb-0">FPO Name</p>
                                    <span class="mt-0 fw-bold">{{ farms?.fpoName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0" *ngIf="farms?.enrollmentType == 'Farmers'">Farms Added Date</p>
                                    <p class="mb-0" *ngIf="farms?.enrollmentType == 'FPO'">FPO Added Date</p>
                                    <span class="mt-0 fw-bold">{{ farms?.createdAt ? (farms?.createdAt | date:
                                        'dd-MM-yyyy') : '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Description</p>
                                    <span class="mt-0 fw-bold">{{ farms?.description || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Latitude</p>
                                    <span class="mt-0 fw-bold">{{ farms?.latitude || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Longitude</p>
                                    <span class="mt-0 fw-bold">{{ farms?.longitude || '-' }}</span>
                                </div>
                            </div>
                            <div class="row mt-3 mb-3" *ngFor="let address of farms?.farmAddress">
                                <div class="col-12 mb-3">
                                    <h6 class="text-bold">Address Details</h6>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Door No</p>
                                    <span class="mt-0 fw-bold">{{ address?.doorNo || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Street Name</p>
                                    <span class="mt-0 fw-bold">{{ address?.streetName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Land Mark</p>
                                    <span class="mt-0 fw-bold">{{ address?.landmark || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Area</p>
                                    <span class="mt-0 fw-bold">{{ address?.area || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">City</p>
                                    <span class="mt-0 fw-bold">{{ address?.city || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">State</p>
                                    <span class="mt-0 fw-bold">{{ address?.state || '-' || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Pin Code</p>
                                    <span class="mt-0 fw-bold">{{ address?.pinCode || '-' }}</span>
                                </div>
                            </div>
                            <div class="row mt-3" *ngFor="let bank of farms?.bankDetails">
                                <div class="col-12 mb-3">
                                    <h6 class="text-bold">Bank Details</h6>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Bank Name</p>
                                    <span class="mt-0 fw-bold">{{ bank?.bankName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Branch</p>
                                    <span class="mt-0 fw-bold">{{ bank?.branchName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Account Holder Name</p>
                                    <span class="mt-0 fw-bold">{{ bank?.accountHolderName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Account Number</p>
                                    <span class="mt-0 fw-bold">{{ bank?.accountNumber || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">IFSC Code</p>
                                    <span class="mt-0 fw-bold">{{ bank?.ifscCode || '-' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                    </div>
                </div>
            </div>
        </div>


        <div class="container-fluid mt-3">
            <div class="row">
                <div class="col-12" *ngIf="this.isErrorShow != 'Farmers' && this.isErrorShow != 'FPO'">
                    <h4 class="fw-bolder mt-2">Business
                        <button class="btn btn-outline-dark btn-sm"
                                (click)="editFarmsDetails(this.userDetailsById?.userRegistrationId,0,this.userDetailsById?.enrollmentType)">
                            Add
                            New
                            <i class="fa fa-plus-circle fw-bold" matTooltip="Click to add"></i></button>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="card direct-chat direct-chat-primary" *ngFor="let business of this.businessInformationList">
                    <div class="card-header bg-white">
                        <h6 class="card-title mt-2">{{business.businessName || '-' }}</h6>
                        <h6 class="text-end">
                            <button class="btn btn-outline-danger btn-sm" (click)="editBusinessDetails(this.userDetailsById?.userRegistrationId,business.businessId,
                             this.userDetailsById?.enrollmentType)">Edit
                                <i class="fa fa-pen" alt="clear" src="assets/img/pen-circle.png" height="32" width="32"
                                   type="button" matTooltip="click to edit"></i></button>
                        </h6>
                        <div class="card-tools">

                        </div>
                    </div>
                    <div class="card-body">
                        <div class="direct-chat-messages overflow-x-hidden">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Business Name</p>
                                    <span class="mt-0 fw-bold">{{ business?.businessName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{ business?.contactNumber || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Business Email</p>
                                    <span class="mt-0 fw-bold">{{ business?.businessEmail || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Business Added Date</p>
                                    <span class="mt-0 fw-bold">{{ business?.createdAt ? (business?.createdAt | date:
                                        'dd-MM-yyyy') : '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Description</p>
                                    <span class="mt-0 fw-bold">{{ business?.description || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Business Region</p>
                                    <span class="mt-0 fw-bold">{{ business?.distributionRegions || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">GST No</p>
                                    <span class="mt-0 fw-bold">{{ business?.gstNo || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Latitude</p>
                                    <span class="mt-0 fw-bold">{{ business?.latitude || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Longitude</p>
                                    <span class="mt-0 fw-bold">{{ business?.longitude || '-' }}</span>
                                </div>
                            </div>
                            <div class="row mt-3 mb-3" *ngFor="let address of business?.businessAddress">
                                <div class="col-12 mb-3">
                                    <h6 class="text-bold">Address Details</h6>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Door No</p>
                                    <span class="mt-0 fw-bold">{{ address?.doorNo || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Street Name</p>
                                    <span class="mt-0 fw-bold">{{ address?.streetName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Land Mark</p>
                                    <span class="mt-0 fw-bold">{{ address?.landmark || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Area</p>
                                    <span class="mt-0 fw-bold">{{ address?.area || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">City</p>
                                    <span class="mt-0 fw-bold">{{ address?.city || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">State</p>
                                    <span class="mt-0 fw-bold">{{ address?.state || '-' || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Pin Code</p>
                                    <span class="mt-0 fw-bold">{{ address?.pinCode || '-' }}</span>
                                </div>
                            </div>
                            <div class="row mt-3" *ngFor="let bank of business?.bankDetails">
                                <div class="col-12 mb-3">
                                    <h6 class="text-bold">Bank Details</h6>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Bank Name</p>
                                    <span class="mt-0 fw-bold">{{ bank?.bankName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Branch</p>
                                    <span class="mt-0 fw-bold">{{ bank?.branchName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Account Holder Name</p>
                                    <span class="mt-0 fw-bold">{{ bank?.accountHolderName || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">Account Number</p>
                                    <span class="mt-0 fw-bold">{{ bank?.accountNumber || '-' }}</span>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <p class="mb-0">IFSC Code</p>
                                    <span class="mt-0 fw-bold">{{ bank?.ifscCode || '-' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true"
     [style.display]="imageModalShow ? 'block' : 'none'" style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="imageModalShow = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <img [src]="selectedImageUrl" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="editProfileModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel"
     aria-hidden="true" [style.display]="editModalShow ? 'block' : 'none'"
     style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit profile</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="editModalShow = false">
                    <span aria-hidden="true" class="h3">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="userFormGroup" (submit)="OnSubmit()">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                       [ngClass]="{'is-invalid': userFormGroup.get('name')?.invalid && userFormGroup.get('name')?.touched,
                                                            'is-valid': userFormGroup.get('name')?.valid && userFormGroup.get('name')?.touched}">
                                <label class="text-gray font-weight-lighter">Name <span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger" *ngIf="userUpdateFormErrors.name">{{ userUpdateFormErrors.name }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="tel" class="form-control" placeholder="Phone Number"
                                       formControlName="phoneNumber"
                                       [ngClass]="{'is-invalid': userFormGroup.get('phoneNumber')?.invalid && userFormGroup.get('phoneNumber')?.touched,
                                                            'is-valid': userFormGroup.get('phoneNumber')?.valid && userFormGroup.get('phoneNumber')?.touched}" minlength="10" maxlength="10">
                                <label class="text-gray font-weight-lighter">Phone Number <span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger" *ngIf="userUpdateFormErrors.phoneNumber">{{ userUpdateFormErrors.phoneNumber }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" placeholder="Email Address"
                                       formControlName="email"
                                       [ngClass]="{'is-invalid': userFormGroup.get('email')?.invalid && userFormGroup.get('email')?.touched,
                                                            'is-valid': userFormGroup.get('email')?.valid && userFormGroup.get('email')?.touched}">
                                <label class="text-gray font-weight-lighter">Email Address <span
                                        class="text-red">*</span></label>
                            </div>
                            <span class="text-danger" *ngIf="userUpdateFormErrors.email">{{ userUpdateFormErrors.email }}</span>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <textarea type="text" class="form-control" placeholder="Self Description"
                                          formControlName="selfDescription"></textarea>
                                <label class="text-gray font-weight-lighter">Self Description</label>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 mb-3">
                            <div class="form-floating">
                                <input type="tel" class="form-control" placeholder="Whatsapp Number"
                                       formControlName="whatsappNumber" minlength="10" maxlength="10">
                                <label class="text-gray font-weight-lighter">Whatsapp Number</label>
                            </div>
                        </div>
                    </div>

                    <div formArrayName="userAddress">
                        <div class="mt-3 mb-5" *ngFor="let address of userAddressArray.controls; let i = index" [formGroupName]="i">
                            <h6 class="fw-bold">Address {{ i+1 }}</h6>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="Door No" formControlName="doorNo">
                                        <label class="text-gray font-weight-lighter">Door No</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="Street Name" formControlName="streetName">
                                        <label class="text-gray font-weight-lighter">Street Name</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="Street Name" formControlName="area">
                                        <label class="text-gray font-weight-lighter">Area</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="Street Name" formControlName="landmark">
                                        <label class="text-gray font-weight-lighter">Land Mark</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="City" formControlName="city">
                                        <label class="text-gray font-weight-lighter">City</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="Street Name" formControlName="pinCode">
                                        <label class="text-gray font-weight-lighter">Pin Code</label>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12 mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" placeholder="Street Name" formControlName="state">
                                        <label class="text-gray font-weight-lighter">State</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div class="row mt-3 mb-3">
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-success">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>